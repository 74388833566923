
import logo from './img/ivan-ros-navarro.jpg';

export const CVData = {
  personalData: {
    name: 'Iván Ros Navarro',
    title: 'Desarrollador Full Stack',
    image: logo,
    contacts: [
      { type: 'email', value: 'ivan@ivanros.com' },
      { type: 'phone', value: '+34 676 104 394' },
      { type: 'location', value: 'Murcia' },
      { type: 'website', value: 'ivanros.com' },
      { type: 'linkedin', value: 'linkedin.com/in/ivanrosnavarro/' },
      { type: 'twitter', value: 'twitter.com/IvanRosWeb' },
      { type: 'github', value: 'github.com/IvanRosNavarro' }
    ]
  },
  sections: [
    {
      type: 'text',
      title: 'Sobre mí',
      description: '',
      content: `**En constante aprendizaje** \n\n #### Soy una persona resolutiva, amable, con amplias dotes de trabajo en equipo, con iniciativa y ganas de seguir aprendiendo. \n\n &nbsp; \n\n Me gusta el cine, la literatura, la música y el
      deporte. En mis ratos libres también desarrollo webs, toco el piano y escribo relatos.`,
      icon: 'usertie',
      xtraClassName: 'no-print'
    },
    {
      type: 'common-list',
      title: 'Educación',
      description: '',
      icon: 'graduation',
      items: [
        {
          title: 'Analista Funcional',
          authority: 'Academia Abrisqueta',
          authorityWebSite: '',
          rightSide: '2008 - 2009'
        },
        {
          title: 'F.P. II Administrador Sistemas Informáticos',
          authority: 'I.E.S. Beniaján',
          authorityWebSite: '',
          rightSide: '2006 - 2008'
        }
      ]
    },
    {
      type: 'experiences-list',
      title: 'Experiencia laboral',
      description: '',
      icon: 'archive',
      items: [
        {
          title: 'Desarrollador web',
          subtitle: 'Responsable técnico e-Commerce',
          company: 'Liwe Española, S.A.',
          description: `+ Desarrollo de la tienda online en Prestashop 1.7\n    - Mantenimiento de la tienda online con Prestashop\n    -  Desarrollo de módulos, conectores con otras herramientas, personalizaciones y mejoras, utilizando PHP, HTML5, CSS3, JavaScript, jQuery\n    - Analizar y buscar mejoras aplicables a la tienda online\n+ Gestionar proyectos con otros compañeros, dos de ellos a mi cargo\n+ Utilización de las herramientas del grupo Atlassian (Jira, Bitbucket, Confluence) para gestionar los proyectos en metodología Agile\n+ Desarrollar web corporativa de la empresa con WordPress utilizando PHP, HTML5, CSS3, JavaScript, jQuery\n+ Ayudar en el desarrollo de aplicaciones web .NET en MVC y también Razor Pages, utilizando C#, HTML5, CSS3, JavaScript, jQuery\n+ Ayudar en el desarrollo de aplicaciones web PHP con Laravel en MVC\n+ Realización de componentes ReactJS para su consumo\n+ Diseño web para las distintas aplicaciones web de la empresa`,
          companyWebSite: 'https://liwe.net',
          companyMeta: 'Industria textil, moda y zapatos',
          datesBetween: '03/2022 - Actualidad',
          descriptionTags: ['PHP', 'Javascript', 'jQuery', 'HTML5', 'CSS3', 'MySQL (María DB)', 'Prestashop', 'Wordpress', 'C#', '.NET', 'SQL Server', 'Agile']
        },
        {
          title: 'Desarrollador web',
          subtitle: 'Responsable técnico e-Commerce',
          company: 'Murcia Musical S.L.',
          description: `+ Desarrollo de la tienda online en Prestashop 1.7\n    -  Se utiliza Web Services para enviar y recibir datos del ERP Odoo (programa utilizado anteriormente, Sage Murano conectado a PrestaShop 1.6).\n    - Creación y edición de módulos para que se adapten a las necesidades\n    - Asignación de productos y adición de imágenes, características, etc...\n    - Creación de banners e imágenes promocionales.\n+ Atención al cliente: chat y teléfono para problemas con la web. Gracias a ello, tengo conocimiento de los problemas que tiene la web para solucionarlos.\n+ Gestión de pedidos online con una persona a mi cargo.\n+ Desarrollo de varias webs de marcas propias de la empresa en Wordpress y una en concreto con WooCommerce.\n    - Creación de plugins personalizados y edición de otros plugins para que se adapten a las necesidades\n    - Creación de banners e imágenes promocionales.\n+ Redes Sociales. Solo en tiempo libre.\n    - Creación de publicaciones con contenido propio y único.\n    - Creación y edición de imágenes que acompañan a cada publicación.\n+ Posicionamiento SEO y SEM. Solo en tiempo libre.\n    - Comprobación del estado de los productos más importantes y optimización de los mismos.\n    - SEO básico en el resto de webs \n    - Gestión de campañas en Google Ads\n+ Análisis con Google Analytics. Solo en tiempo libre.`,
          companyWebSite: 'https://klavier.es',
          companyMeta: 'Venta al por menor',
          datesBetween: '10/2014 - 03/2022',
          descriptionTags: ['PHP', 'Javascript', 'jQuery', 'HTML5', 'CSS3', 'MySQL', 'Prestashop', 'Wordpress', 'Photoshop', 'Ilustrator']
        },
        {
          title: 'Desarrollador Web/Aplicaciones',
          subtitle: ' ',
          company: 'Industrias Royal Termic, S.L.',
          description: '- Desarrollar y maquetar la página web de la empresa con Wordpress en Español, Inglés y Portugués.\n- Desarrollar y maquetar la aplicación web en PHP que enlaza con el servicio web que permite realizar cambios en los productos.\n- Mantenimiento informático de los PC, servidores, impresoras, etc...\n- Creación y mantenimiento de las redes sociales (en momentos puntuales).\n- SEO de las webs (en momentos puntuales).',
          companyWebSite: 'https://rointe.com',
          companyMeta: 'Venta al por mayor',
          datesBetween: '10/2013 - 10/2014',
          descriptionTags: ['PHP', 'Javascript', 'jQuery', 'HTML5', 'CSS3', 'JSON', 'MySQL', 'SQL Server', 'Wordpress', 'Photoshop']
        },
        {
          title: 'Desarrollador web',
          subtitle: ' ',
          company: 'Murcia Musical S.L.',
          description: `- Desarrollar la web a media jornada (tardes). Basado en Wordpress, utilizando Jquery, HTML5, CSS3 y Responsive Design.\n- Diseño de carteles, flyers, lonas y otra publicidad, utilizando Photoshop,  Illustrator e InDesign\n- Posicionamiento SEO\n- Mantenimiento de las redes sociales`,
          companyWebSite: 'https://klavier.es',
          companyMeta: 'Venta al por menor',
          datesBetween: '05/2013 - 10/2013',
          descriptionTags: ['PHP', 'Javascript', 'jQuery', 'HTML5', 'CSS3', 'MySQL', 'Wordpress', 'Photoshop', 'Ilustrator']
        },
        {
          title: 'Desarrollador web',
          subtitle: ' ',
          company: 'Eikondata S.L.',
          description: `- Desarrollar la web a media jornada (tardes). Basado en Wordpress, utilizando Jquery, HTML5, CSS3 y Responsive Design.\n- Diseño de carteles, flyers, lonas y otra publicidad, utilizando Photoshop,  Illustrator e InDesign\n- Posicionamiento SEO\n- Mantenimiento de las redes sociales`,
          companyWebSite: 'https://www.eikondata.es',
          companyMeta: 'Programación, Consultoría y otras Activ. Informáticas',
          datesBetween: '01/2013 - 03/2013',
          descriptionTags: ['C#', '.NET', 'Javascript', 'jQuery', 'HTML5', 'CSS3', 'LINQ', 'Razor', 'SQL Server', 'Scrum']
        }
      ]
    },
    /*{
      type: 'projects-list',
      title: 'Proyectos',
      description: '',
      icon: 'tasks',
      groups: [
        {
          sectionHeader: 'Liwe Española, S.L.',
          description: '',
          items: [
            { title: 'Tarjetas regalo', projectUrl: 'optional', description: 'Optional' },
            { title: 'Project', projectUrl: 'optional', description: 'Optional' },
            { title: 'Project', projectUrl: 'optional', description: 'Optional' }
          ]
        }
      ]
    },
    {
      type: 'common-list',
      title: 'Certificados',
      description: '',
      icon: 'comments',
      items: [
        {
          title: 'Some Conferences / 2019',
          authority: 'SomeConf',
          authorityWebSite: 'https://www.someconf.somesome'
        },
        {
          title: 'Some Conferences / 2019',
          authority: 'SomeConf',
          authorityMeta: 'Speaker',
          authorityWebSite: 'https://www.someconf.somesome',
          rightSide: 'test'
        },
        {
          title: 'Some Conferences / 2012',
          authorityMeta: 'Speaker'
        }
      ]
    },*/
    {
      type: 'common-list',
      title: 'Idiomas',
      description: '',
      icon: 'language',
      items: [
        {
          authority: 'Español',
          authorityMeta: 'Nativo'
        },
        {
          authority: 'Inglés',
          authorityMeta: 'Medio | B1 - sin certificar'
        }
      ]
    },
    {
      type: 'tag-list',
      title: 'Habilidades',
      description: '',
      icon: 'rocket',
      items: ['PHP', 'Laravel', 'C#', '.NET', 'Javascript', 'jQuery', 'ReactJS', 'HTML5', 'CSS3', 'MySQL', 'SQL Server', 'Prestashop', 'Wordpress', 'Git', 'Jira', 'Photoshop']
    },
    {
      type: 'tag-list',
      title: 'Intereses',
      description: '',
      icon: 'cubes',
      items: ['Cine', 'Literatura', 'Música', 'Deporte'],
      xtraClassName: 'no-print'
    }
  ]
}
